@import "../../../data/styles.css";

.article {
	display: flex;
	max-width: 320px;
}

.article a {
	text-decoration: none;
	width: 100%;
}

.article-left-side {
	min-width: 20%;
	max-width: 20%;
}

.article-right-side {
	mix-blend-mode: normal;
	padding: 20px;
}

.article-date {
	font-size: 13px;
	color: var(--tertiary-color);
}

.article-title {
	color: var(--primary-color);
	font-size: 16px;
	font-weight: 600;
}

.article-image {
	background-position: 50%;
    background-size: cover;
    height: 160px;
	margin-bottom: 12px;
}

.article-description {
	padding-top: 10px;
	font-size: 12px;
	color: var(--secondary-color);
	font-weight: 480;
	line-height: 25px;
}

.article-flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.article-link {
	padding-top: 10px;
	font-size: 14px;
	color: var(--link-color);
	font-weight: 700;
}

@media (max-width: 1024px) {
	.article-left-side {
		min-width: 10%;
		max-width: 10%;
	}

	.article-right-side {
		flex-basis: 90%;
		min-width: 85%;
		max-width: 85%;
	}
}
