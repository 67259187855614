@import "/src/data/styles.css";

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background-color: #383636;
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgb(0, 0, 0);
}

.page-content {
	background-color: var(--background-color);
	position: relative;
}

.page-content:before,
.page-content:after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	width: calc((100% - 1200px) / 2);
	background-color: var(--side-background-color);
	box-shadow: inset rgba(0, 0, 0, 0.16) 0px -10px 36px 0px, inset rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.page-content:before {
	left: 0;
	z-index: 1;
}

.page-content:after {
	right: 0;
	z-index: 1;
}

.content-wrapper {
	max-width: 1000px;
	min-height: 100vh;
	margin: 0 auto;
}

.title {
	color: var(--primary-color);
	font-family: var(--secondary-font);
	font-size: 45px;
	font-weight: 700;
	width: 70%;
}

.subtitle {
	padding-top: 10px;
	color: var(--secondary-color);
	font-size: 16px;
	line-height: 28px;
	width: 70%;
	margin-top: 25px;
}

.page-footer {
	position: block;
}

.page-footer::before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	border-top: 2px solid var(--tertiary-color);
}

@media (max-width: 1270px) {
	.page-content:before,
	.page-content:after {
		width: calc((100% - 90%) / 2);
	}

	.content-wrapper {
		max-width: 90%;
		padding-left: 10%;
		padding-right: 10%;
	}
}

@media (max-width: 1024px) {
	.page-content:before,
	.page-content:after {
		width: calc((100% - 950px) / 2);
	}

	.content-wrapper {
		max-width: 980px;
	}
}

@media (max-width: 800px) {
	.content-wrapper {
		max-width: 90%;
		margin: 0 auto;
		padding: 0 10px;
	}

	.page-content:before,
	.page-content:after {
		display: none;
	}

	.title {
		width: 100%;
	}

	.subtitle {
		width: 100%;
	}
}


.loading-wrap {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.loading-text {
	color: #fbae17;
	display: inline-block;
	margin-left: 8px;
	font-size: 18px;
	font-weight: 600;
}
.bounceball {
	position: relative;
	display: inline-block;
	height: 37px;
	width: 15px;
}
.bounceball:before {
	position: absolute;
	content: '';
	display: block;
	top: 0;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: #fbae17;
	transform-origin: 50%;
	animation: bounce 500ms alternate infinite ease;
}
@keyframes bounce {
	0% {
		top: 30px;
		height: 5px;
		border-radius: 60px 60px 20px 20px;
		transform: scaleX(2);
   }
	35% {
		height: 15px;
		border-radius: 50%;
		transform: scaleX(1);
   }
	100% {
		top: 0;
   }
}