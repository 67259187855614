.articles-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.articles-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.articles-main-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
	min-height: 68vh;
}

.articles-container {
	display: flex;
	flex-direction: column;
}

.articles-wrapper {
	padding-top: 50px;
	padding-bottom: 50px;
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
}

.articles-article {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	background: var(--elements-background-color); 
}

.articles-article:hover {
	background: var(--side-background-color);
	transition: background-color 0.3s ease-in-out;
	transform: scale(1.01);
}

@media (max-width: 1024px) {
	.articles-title {
		width: 100% !important;
	}

	.articles-subtitle {
		width: 100% !important;
	}
}
