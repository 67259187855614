.about-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.about-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.about-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.about-main {
	display: flex;
}

.about-right-side {
	flex-basis: 900px;
}

.about-title {
	width: 100% !important;
}

.about-subtitle {
	width: 80% !important;
}

.about-left-side {
	display: flex;
	flex-direction: column;
	flex-basis: 300px;
}

.about-image-container {
	width: 370px;
	height: 370px;
	position: relative;
}

.about-image-wrapper {
	border-radius: 51% 49% 77% 23% / 65% 50% 50% 35%;
    overflow: hidden;
}

.about-image-wrapper-loading {
	background: #f0f0f0;
	margin: 0 auto;
	border-radius: 51% 49% 77% 23% / 65% 50% 50% 35%;
    overflow: hidden;
	width: 100%;
	height: 100%;
	position: relative;
}

.about-image-wrapper-loading:before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: -150px;
	height: 100%;
	width: 100%;
	background: linear-gradient(to right, transparent 0%, #e0e0e0 50%, transparent 100%);
	animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
	0% {
	  transform: translateX(-150%);
	}
	100% {
	  transform: translateX(150%);
	}
}

.about-image {
	max-width: 100%;
    height: auto;
	vertical-align: middle;
	position: relative;
	z-index: 2;
}

.about-image-wrap-circle {
	position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid var(--secondary-color);
    border-radius: 50%;
    z-index: 1;
	left: 4%;
}

.about-socials {
	padding-top: 50px;
	padding-bottom: 50px;
}

.about-socials-mobile {
	display: none;
}

.about-after-title {
	display: flex;
}

.about-works {
	flex-basis: 600px;
	padding-top: 30px;
	margin-right: 80px;
}

@media (max-width: 1024px) {
	.about-logo {
		z-index: 100;
	}

	.about-main {
		flex-direction: column;
	}

	.about-after-title {
		flex-direction: column;
	}

	.about-subtitle {
		padding-top: 20px !important;
		width: 100% !important;
		text-align: justify;
	}

	.about-left-side {
		flex-basis: 100%;
		order: 1;
		align-items: center;
	}

	.about-right-side {
		flex-basis: 100%;
		order: 2;
	}

	.about-image-wrapper {
		display: inline-block;
		width: 100%;
		border-radius: 10%;
		transform: rotate(3deg);
		margin-top: 20px;
	}

	.about-image-wrapper-loading {
		display: inline-block;
		width: 100%;
		border-radius: 10%;
		transform: rotate(3deg);
		margin-top: 20px;
	}

	.about-image-wrap-circle {
		display: none;
	}

	.about-image-container {
		padding-bottom: 40px;
	}

	.about-socials {
		display: none;
	}

	.about-socials-mobile {
		padding-top: 30px;
		display: flex;
	}

	.about-works {
		flex-basis: auto;
		margin-left: -25px;
		margin-right: -25px;
	}
}

@media (max-width: 600px) {
	.about-container {
		padding-top: 60px;
	}

	.about-image-container {
		width: 80%;
	}
}
