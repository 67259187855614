@import "../../../data/styles.css";

.nav-container {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 3vh;
	z-index: 999;
}

.nav-background {
	width: 380px;
	height: 40px;
	padding-left: 0%;
	padding-right: 0%;
	background: var(--elements-background-color);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 40px;
}

.nav-list {
	display: flex;
	justify-content: space-between;
	list-style: none;
	align-items: center;
	margin-left: -16px;
	margin-right: 20px;
	margin-top: 11px;
}

.nav-item {
	font-weight: bold;
	font-size: 80%;
}

.nav-item.active a {
	color: var(--link-color) !important;
}

.nav-item a {
	text-decoration: none;
	color: var(--primary-color);
}

.nav-item a:hover {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.switch {
	position: fixed;
	top: 3vh;
	right: 200px;
	display: inline-block;
	width: 50px;
	height: 28px;
	z-index: 999;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}
  
.slider:before {
	position: absolute;
	content: "";
	height: 30px;
	width: 30px;
	left: -2px;
	bottom: 4px;
	top: 0;
	bottom: 0;
	margin: auto 0;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	box-shadow: 0 0px 15px #2020203d;
	background: white url('https://i.ibb.co/7JfqXxB/sunny.png');
	background-repeat: no-repeat;
	background-position: center;
}
  
input:checked + .slider {
	background-color: #333;
}

input:focus + .slider {
	box-shadow: 0 0 1px #333;
}

input:checked + .slider:before {
	-webkit-transform: translateX(24px);
	-ms-transform: translateX(24px);
	transform: translateX(24px);
	background: white url('https://i.ibb.co/FxzBYR9/night.png');
	background-repeat: no-repeat;
	background-position: center;
}
  
.slider.round {
	border-radius: 34px;
}
  
.slider.round:before {
	border-radius: 50%;
}

@media (max-width: 600px) {
	.navbar {
		margin-left: 25%;
		margin-right: 25%;
		width: 80%;
		font-size: 80%;
	}

	.nav-background {
		width: 80%;
		height: 40px;
	}

	.nav-item {
		font-weight: bold;
	}

	.switch {
		right: 20px;
		top: 32vh;
	}
}
